*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    /* background: #212A3E; */
    /* background: #384B70; */
    background: rgb(56,75,112);
    background: linear-gradient(90deg, rgba(56,75,112,.9) 0%, rgba(56,75,112,1) 50%, rgba(56,75,112,0.9) 100%);

}