/* SiteBar */
.hover{
  border:none;
  position: relative;
}

.hover:hover{
  border: none;
}

.hover::after {
  content: '';
  position: absolute;
  width: 0px;
  height: 2.5px;
  left: 50%;
  bottom: 0;
  background-color: white;
  transition: all ease-in-out .4s;
}

.hover:hover::after {
  width: 100%;
  left: 0;
}

/* Home */
.home{
  background: linear-gradient(-45deg, #ffcc3e, #ff2828, #451ea7, #1b1a61);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  } 
}

.title{
  animation: text-shadow 2.5s ease-in-out infinite;
}

@keyframes text-shadow {
  0% {  
      transform: translateY(0);
      text-shadow: 
          0 0 10px #0c2ffb, 
          0 0 10px #2cfcfd, 
          0 0 10px #fb203b, 
          0 0 10px #fefc4b;
  }

  20% {  
      transform: translateY(-1em);
      text-shadow: 
          0 0.125em 10px #0c2ffb, 
          0 0.25em 10px #2cfcfd, 
          0 -0.125em 10px #fb203b, 
          0 -0.25em 10px #fefc4b;
  }

  40% {  
      transform: translateY(0.5em);
      text-shadow: 
          0 -0.0625em 10px #0c2ffb, 
          0 -0.125em 10px #2cfcfd, 
          0 0.0625em 10px #fb203b, 
          0 0.125em 10px #fefc4b;
  }
  
 60% {
      transform: translateY(-0.25em);
      text-shadow: 
          0 0.03125em 10px #0c2ffb, 
          0 0.0625em 10px #2cfcfd, 
          0 -0.03125em 10px #fb203b, 
          0 -0.0625em 10px #fefc4b;
  }

  80% {  
      transform: translateY(0);
      text-shadow: 
          0 0 10px #0c2ffb, 
          0 0 10px #2cfcfd, 
          0 0 10px #fb203b, 
          0 0 10px #fefc4b;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}


/* Profile */
.profile{
  background-color: rgb(58, 58, 58);
  height: 100vh;
}

.shadowtext{
  text-shadow: 9px 9px 14px #000000;
}


/* About */
.about{
  background-color: rgb(90, 90, 90);
  height: 100vh;
}